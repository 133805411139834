<template>
  <div>
    <transition name="ani">
      <div class="zhezhaoceng" v-if="_CoinChance">
        <div class="container">
          <div class="container-title">
            <span style="flex: 1;"></span>
            <span>
              <van-icon name="cross" size="20" @click="closeCoinChance" />
            </span>
          </div>

          <p class="container-shuoming">活跃度不足</p>

          <p class="container-shuoming container-item">您确认以下哪种道具兑换</p>

          <div class="container-list container-item">
            <div 
              :class="['container-list-item', { 'container-list-slecthigh': s_i == i }]" 
              v-for="(x, s_i) in zhifuType" :key="x.type"
              @click="select(s_i)">
              <span style="font-size: 20px;">{{ x.jine }}</span>
              <span>{{ x.name }}</span>
            </div>
          </div>

          <div class="container-qvkankan container-item">
            <span class="container-qvkankan-text">看广告获得{{ active }}活跃度</span>
            <span>
              <van-button 
                round 
                size="mini" 
                color="linear-gradient(to right, #FF4982, #FF3F3F)"
                @click="qvzhuanqv">
                <div style="margin: 0 3px;">去赚取</div>
              </van-button>
            </span>
          </div>

          <div class="container-btn">
            <span>
              <van-button size="large" color="#E54320" round @click="duihuanChance">确认</van-button>
            </span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    CoinChance: {
      type: Boolean,
      required: true
    },
    zhifuType: {
      type: Array,
      default: () => {}
    },
    active: {
      type: Number
    }
  },

  computed: {
    _CoinChance: {
      get () {
        return this.CoinChance
      },
      set () {
        this.$emit('closeCoinChance')
      }
    }
  },

  data () {
    return {
      type: 0,
      i: 0
    }
  },

  methods: {
    closeCoinChance () {
      this._CoinChance = false
    },

    select (i) {
      this.i = i
    },

    duihuanChance () {
      let type = this.zhifuType[this.i].type
      this._CoinChance = false
      this.$emit('duihuanChance', type)
    },

    qvzhuanqv () {
      this._CoinChance = false
      this.$emit('qvzhuanqv')
    }
  }
}
</script>

<style lang="less" scoped>
.zhezhaoceng {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  .container {
    width: 90%;
    margin: auto;
    padding: 30px 15px;
    box-sizing: border-box;
    border-radius: 20px;
    background: linear-gradient(#FFFFE0, #fff);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    &-item {
      margin-bottom: 25px;
    }
    &-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-shuoming {
      font-size: 18px;
      color: #832604;
    }
    &-list {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      &-item {
        width: 22%;
        aspect-ratio: 1/1;
        color: #FA6E0B;
        border-radius: 10px;
        border: 1px solid #FFDAB8;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
      &-slecthigh {
        border: 1px solid #FA6E0B;
        background: #FFFAF1;
      }
    }
    &-qvkankan {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      padding: 10px;
      background: #FFFAF1;
      border: 1px solid #FFDAB8;
      box-sizing: border-box;
      &-text {
        color: #832604;
      }
    }
    &-btn {
      width: 100%;
    }
  }
}
.ani-enter {
  opacity: 0;
}
.ani-leave {
  opacity: 1;
}
.ani-leave-to {
  opacity: 0;
}
.ani-enter-active,
.ani-leave-active {
  transition: all .5s;
}
</style>