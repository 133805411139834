<template>
<!-- 
  @touchmove.prevent.stop @wheel.prevent.stop
 -->
  <div>
    <transition name="ani">
      <div class="zhezhaoceng" v-if="_SelectAddress" >
        <div class="container">
          <div class="container-title">
            <span style="opacity: 0;">
              <van-icon name="cross" size="20" />
            </span>
            <span style="color: #832604; font-size: 20px;">选择收货地址</span>
            <span>
              <van-icon name="cross" size="20" @click="closeSelectAddress" />
            </span>
          </div>

          <div class="container-noneAddress" v-if="addressList.length == 0">
            <img class="container-noneAddress-img" src="../img/mark.png" alt="">
            <p class="container-noneAddress-address" @click="addAddress">+添加收货地址</p>
          </div>

          <div class="addressList" v-else>
            <div class="addressList-list">
              <van-radio-group v-model="radio">
                <div class="addressList-item" v-for="x in addressList" :key="x.id">
                  <div class="addressList-item-radio">
                    <span>{{ x.userName }} {{ x.telNum }}</span>
                    <van-radio :name="x.id" checked-color="#E54320" icon-size="16px"></van-radio>
                  </div>
                  <p class="addressList-item-address">{{ x.detailAddress }}</p>
                </div>
              </van-radio-group>
            </div>

            <div class="addressList-btn">
              <p class="addressList-btn-address" @click="addAddress">+添加收货地址</p>
              <span>
                <van-button size="large" color="#E54320" round @click="confirm">确认</van-button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <loading :loading="loading" @closeLoading="closeLoading" />
  </div>
</template>

<script>

import { getAddress, getJIangli } from '@/api/Ge/port'
import Loading from './Loading'
import { Toast } from 'vant'
import { mapState } from 'vuex'

export default {
  components: { Loading },

  props: {
    SelectAddress: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapState({
      rewardId: state => state.zhuanpan.rewardId
    }),

    _SelectAddress: {
      get () {
        return this.SelectAddress
      },
      set () {
        this.$emit('closeSelectAddress')
      }
    }
  },

  created () {
    this.getAddress()
  },

  data () {
    return {
      radio: '',
      addressList: [],
      loading: false
    }
  },

  methods: {
    closeSelectAddress () {
      this._SelectAddress = false
    },

    closeLoading () {
      this.closeLoading = false
    },

    addAddress () {
      this.$router.push({
        path: '/slyderAdventuresAddAddress',
        query: {
          session: this.$route.query.session
        }
      })
    },

    getAddress () {
      getAddress().then(res => {
        if (res.data.code === 0) {
          res.data.data.records.forEach(x => {
            x.detailAddress = `${x.provinceName}${x.cityName}${x.countyName}`
          })
          this.addressList = res.data.data.records
        }
      })
    },

    confirm () {
      if (this.radio) {
        this.loading = true
        getJIangli({ id: this.rewardId, logisticsId: this.radio }).then(res => {
          if (res.data.code === 0) {
            this.loading = false
            this.$emit('closeSelectAddress')
          } else {
            Toast(res.data.message)
            this.$emit('closeSelectAddress')
            this.loading = false
          }
        }).catch(() => {
          this.loading = false
        })
      } else {
        Toast('请选择地址')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.zhezhaoceng {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
        
  .container {
    width: 90%;
    margin: auto;
    padding: 30px 15px;
    box-sizing: border-box;
    border-radius: 20px;
    background:  #fff;
    &-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    &-noneAddress {
      width: 100%;
      height: 100px;
      border: 1px solid #DEDEDE;
      border-radius: 5px;
      background: #F6F6F6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      &-img {
        width: 20px;
      }
      &-address {
        font-size: 14px;
        color: #333;
        margin-top: 5px;
      }
      &-address:active {
        color: #832604;
      }
    }
    .addressList {
      &-list {
        max-height: 350px;
        overflow: auto;
      }
      &-item {
        background: #F6F6F6;
        border-radius: 3px;
        padding: 13px 15px;
        box-sizing: border-box;
        margin-bottom: 15px;
        &-radio {
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        &-address {
          margin-top: 5px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          font-size: 12px;
          color: #999;
        }
      }
      &-btn {
        &-address {
          font-size: 14px;
          color: #333;
          margin-bottom: 5px;
        }
        &-address:active {
          color: #832604;
        }
        .van-button {
          height: 48px;
        }
      }
    }
  }
}
.ani-enter {
  opacity: 0;
}
.ani-leave {
  opacity: 1;
}
.ani-leave-to {
  opacity: 0;
}
.ani-enter-active,
.ani-leave-active {
  transition: all .5s;
}
</style>