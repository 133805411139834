import { fetchGet, fetchPost } from '@/router/https'

// 获取二维码
export const getErCode = (params) => fetchGet('/mallapp/userinfo/generatePoster', params)

// 上传图片
export const upload = (param) => fetchPost('/mallapp/feedbackrelease/uploadFile', param)

// 获取平台优惠券
export const getTicket  = (params = {}) => fetchGet('/createrapp/coupon/getCouponMore', params)

// 侠客集市/红尘坊是否开启金叶子支付
export const isHonemi = (params) => fetchGet('/mallapp/orderinfo/cloudAndWelfare', { params })

// 账户剩余
export const getUserMoney = () => fetchGet('/mallapp/orderfictitious/getAccount')

// 兑换领取优惠券
export const duihuanTicket  = (data = {}) => fetchPost('/createrapp/coupon/exchangeCoupon?couponId=' + data.couponId)

// 折扣商品列表
export const getDiscountPage  = (params = {}) => fetchGet('/mallapp/discountActive/goodsActivePage', params)

// 查询今日场次
export const searchActiveTime  = (params = {}) => fetchGet('/mallapp/discountActive/discountActiveList', params)

// 查询今日场次
export const getAddress = (params = {}) => fetchGet('/mallapp/useraddress/page', params)

// 大转盘
// 大转盘活动列表
export const getActivePage = (params = {}) => fetchGet('/mallapp/slyderAdventures/getActive', params)

// 抽奖
export const choujiang = (data = {}) => fetchPost('/mallapp/rewardRecord/participation', data)

// 领取奖励
export const getJIangli = (data = {}) => fetchPost('/mallapp/rewardRecord/exchangeGoods', data)

// 任务列表
export const getTiskList = () => fetchGet('/mallapp/activeAward/all')

// 奖励记录
export const getJiangliList = (params) => fetchGet('/mallapp/rewardRecord/page', params)

// 文旅
// 文旅详情 
export const getTravelPage = (params = {}) => fetchGet('/mallapp/orderfictitious/virtualGoodsDetails', params)

// 文旅商品列表
export const getTravelListPage = (params = {}) => fetchGet('/mallapp/orderfictitious/getShopGoodsList', params)

// 下单
export const getGuishiOrder = (data = {}) => fetchPost('/mallapp/orderfictitious/buyGoods', data)

// 摇一摇
// 摇一摇详情
export const getYaoyiyaoPage = (params = {}) => fetchGet('/createrapp/shakeActivity/getShakeActivities', params)

// 摇一摇抽奖
export const getsuipianPage = (params = {}) => fetchGet('/createrapp/shakeActivity/startLottery', params)

// 奖励记录
export const getSuipianliList = (params = {}) => fetchGet('/createrapp/shakeActivityIncome/getMyPrize', params)

// 南市
// 详情
export const getSouthDetail = (params = {}) => fetchGet('/mallapp/goodAuction/goodAuction', params)

// 保证金支付
export const zhifuBaoJin = (params = {}) => fetchGet('/mallapp/orderinfo/marginPay', params)

// 关注提醒
export const guanzhutixing = (params = {}) => fetchGet('/mallapp/goodAuction/remind', params)

// 出价
export const chujia = (params = {}) => fetchGet('/mallapp/goodAuction/auction', params)

// 养生馆
export const getTaocan = () => fetchGet('/mallapp/healthSetMeal/getHealthMeal')

// 养生馆下单
export const yangshengZhifu = (data) => fetchPost(`/mallapp/healthOrder/payOrder?healthSetMealId=${data.id}&payType=${data.payType}`)

// 订单支付
export const orderZhifu = (data = {}) => fetchPost('/mallapp/healthOrder/orderPayment', data)

// 支付宝支付
export const zhifubaoPay = (data = {}) => fetchPost('/mallapp/healthOrder/orderPaymentAli', data)