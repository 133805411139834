<template>
  <div>
    <transition name="ani">
      <div class="zhezhaoceng" v-if="_NoneJiang">
        <div class="container">
          <div class="container-title container-item">
            <span style="opacity: 0;">
              <van-icon name="cross" size="20" />
            </span>
            <span style="color: #832604; font-size: 20px;">您未中奖</span>
            <span>
              <van-icon name="cross" size="20" @click="closeNoneJiang" />
            </span>
          </div>

          <img class="container-img container-item" src="../img/lian.png" alt="">

          <p class="container-text container-item">您没有抽中，再接再厉吧</p>

          <div class="container-qvkankan container-item">
            <span class="container-qvkankan-text">看广告获得{{ active }}活跃度</span>
            <span>
              <van-button 
                round 
                size="mini" 
                color="linear-gradient(to right, #FF4982, #FF3F3F)"
                @click="qvzhuanqv">
                <div style="margin: 0 3px;">去赚取</div>
              </van-button>
            </span>
          </div>

          <div class="container-btn">
            <span>
              <van-button color="#E54320" plain round @click="closeNoneJiang">知道了</van-button>
            </span>

            <span>
              <van-button color="#E54320" round @click="tryAgain">再来一次</van-button>
            </span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    NoneJiang: {
      type: Boolean,
      required: true
    },
    active: {
      type: Number
    }
  },

  computed: {
    _NoneJiang: {
      get () {
        return this.NoneJiang
      },
      set () {
        this.$emit('closeNoneJiang')
      }
    }
  },

  data () {
    return {

    }
  },

  methods: {
    closeNoneJiang () {
      this._NoneJiang = false
    },

    tryAgain () {
      this._NoneJiang = false
      this.$emit('tryAgain')
    },

    qvzhuanqv () {
      this._NoneJiang = false
      this.$emit('qvzhuanqv')
    }
  }
}
</script>

<style lang="less" scoped>
.zhezhaoceng {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  .container {
    width: 90%;
    margin: auto;
    padding: 30px 15px;
    box-sizing: border-box;
    border-radius: 20px;
    background: linear-gradient(#FFFFE0, #fff);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    &-item {
      margin-bottom: 15px;
    }
    &-title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-img {
      width: 120px;
    }
    &-qvkankan {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      padding: 10px;
      background: #FFFAF1;
      border: 1px solid #FFDAB8;
      box-sizing: border-box;
      &-text {
        color: #832604;
      }
    }
    &-btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .van-button {
        width: 140px;
      }
    }
  }
}
.ani-enter {
  opacity: 0;
}
.ani-leave {
  opacity: 1;
}
.ani-leave-to {
  opacity: 0;
}
.ani-enter-active,
.ani-leave-active {
  transition: all .5s;
}
</style>