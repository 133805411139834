<template>
  <div>
    <transition name="ani">
      <div class="zhezhaoceng" v-if="_ShareFriend">
        <span></span>

        <div class="container" ref="sharePoster">
          <img v-if="endInfo.prize == '1'" src="../img/poster1.png" alt="">
          <img v-if="endInfo.prize == '2'" src="../img/poster2.png" alt="">
          <img v-if="endInfo.prize == '3'" src="../img/poster3.png" alt="">
          <img v-if="endInfo.prize == '4'" src="../img/poster4.png" alt="">
          <img v-if="endInfo.prize == '5'" src="../img/poster5.png" alt="">
          <img v-if="endInfo.prize == '6'" src="../img/poster6.png" alt="">

          <div class="container-share">
            <span style="height: 70px;"></span>

            <div class="container-ticketBox" v-if="endInfo.prize == '1'">
              <div class="container-ticketBox-ticket">
                <img src="../img/ticket.png" alt="">
                <div class="container-ticketBox-ticket-textBox">
                  <p style="color: #F52555;">{{ endInfo.coupon.name }}</p>
                  <p style="font-size: 14px;">{{ endInfo.coupon.discountType == '0' ? '折扣券' : '满减券' }}</p>
                </div>
              </div>
            </div>

            <div class="container-shopBox" v-if="endInfo.prize == '6'">
              <img class="container-shopBox-img" style="" :src="endInfo.prizeImg" alt="">
            </div>

            <div class="container-share-er">
              <div>
                <p style="font-size: 14px; color: #333;">来Win生活玩游戏有机会</p>
                <p style="font-size: 14px; color: #333;">获得现金红包哦</p>
                <p style="font-size: 12px; color: #999;">快来跟我一起玩吧！</p>
              </div>

              <div>
                <img class="container-share-img" :src="src" alt="">
              </div>
            </div>
          </div>
        </div>

        <div class="zhanShareBox">
          <div class="zhanShareBox-close">
            <van-icon size="20" name="cross" @click="closeShareFriend" />
          </div>
          <div class="zhanShareBox-item" @click="savePicToPhone">
            <img class="zhanShareBox-item-img" src="../img/share1.png" alt="">
            <span class="zhanShareBox-item-text">保存海报到相册</span>
          </div>
          <div class="zhanShareBox-item" @click="sharePoster('0')">
            <img class="zhanShareBox-item-img" src="../img/share2.png" alt="">
            <span class="zhanShareBox-item-text">微信</span>
          </div>
          <div class="zhanShareBox-item" @click="sharePoster('1')">
            <img class="zhanShareBox-item-img" src="../img/share3.png" alt="">
            <span class="zhanShareBox-item-text">朋友圈</span>
          </div>
          <div class="zhanShareBox-item" @click="sharePoster('2')">
            <img class="zhanShareBox-item-img" src="../img/share4.png" alt="">
            <span class="zhanShareBox-item-text">QQ</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import html2canvas from 'html2canvas'
import { upload } from '@/api/Ge/port'

export default {
  props: {
    ShareFriend: {
      type: Boolean,
      required: true
    },
    endInfo: {
      type: Object,
    },
    src: {
      type: String,
    },
  },

  computed: {
    _ShareFriend: {
      get () {
        return this.ShareFriend
      },
      set () {
        this.$emit('closeShareFriend')
      }
    }
  },

  data () {
    return {
      num: '',
      url: ''
    }
  },

  methods: {
    closeShareFriend () {
      this._ShareFriend = false
    },

    sharePoster (num) {
      let params = {
        num,
        url: this.url,
        title: '大转盘',
      }
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.shareOther.postMessage(params)
      }
      if (isAndroid) {
        window.android.shareImg(JSON.stringify(params))
      }
    },

    savePicToPhone () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.savePicToPhone.postMessage(this.url)
      }
      if (isAndroid) {
        window.android.savePicToPhone(this.url)
      }
    },

    toImage () {
      const canvasBox = this.$refs.sharePoster
      const options = {
        backgroundColor: 'white',
        useCORS: true,
        scale: 1
      }
      html2canvas(canvasBox, options).then((canvas) => {
        const url = canvas.toDataURL('image/png')
        this.transformImg(url)
      })
    },

    transformImg(base) {
      var img = this.base64ToImg(base, 'ali.jpg')
      let formData = new window.FormData()
      formData.append('file', img)
      formData.append('fileType', 'imgage/png'),
      formData.append('dir', 'material')
      upload(formData).then(res => {
        if (res.data.code === 0) {
          this.url = res.data.data
        }
      })
    },

    base64ToImg(url, filename) {
      var arr = url.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    }
  }
}
</script>

<style lang="less" scoped>
.zhezhaoceng {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: stretch;
  z-index: 9999;
}
.container {
  width: 80%;
  margin: auto;
  position: relative;
  &-share {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 25px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-img {
      width: 70px;
      height: 70px;
    }
    &-er {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &-ticketBox {
    display: flex;
    justify-content: center;
    &-ticket {
      width: 80%;
      position: relative;
      &-textBox {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10%;
      }
    }
  }
  &-shopBox {
    display: flex;
    justify-content: center;
    &-img {
      border-radius: 50%;
      width: 80px;
      height: 80px;
      object-fit: cover;
    }
  }
}
.zhanShareBox {
  background: #fff;
  padding: 40px 10px 20px;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  &-close {
    position: absolute;
    top: 20px;
    right: 10px;
  }
  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    &-img {
      width: 50px;
      height: 50px;
      margin-bottom: 10px;
    }
    &-text {
      font-size: 12px;
    }
  }
  &-item:active {
    background: #f6f6f6;
  }
}
.ani-enter {
  opacity: 0;
}
.ani-leave {
  opacity: 1;
}
.ani-leave-to {
  opacity: 0;
}
.ani-enter-active,
.ani-leave-active {
  transition: all .5s;
}
</style>