<template>
  <div>
    <transition name="ani">
      <div class="zhezhaoceng" v-if="_show">
        <p class="zhezhaoceng-title">手气不错哦，抽中了{{ endInfo.typeName }}~</p>

        <div class="container">
          <img class="container-img" src="../img/jiang.png" alt="">
          <div style="height: 60px;"></div>

          <div class="container-jine container-item" v-if="endInfo.prize == '2' || endInfo.prize == '3' || endInfo.prize == '4' || endInfo.prize == '5'">
            <span style="font-size: 40px;">{{ endInfo.prizeInfo }}</span>
            <span style="font-size: 18px;">{{ endInfo.prizeName }}</span>
          </div>

          <div class="container-ticket container-item" v-if="endInfo.prize == '1'">
            <img class="container-ticket-img" src="../img/ticket.png" alt="">
            <div class="container-ticket-ticketName">
              <p style="font-size: 20px; color: #F52555;">{{ endInfo.coupon.name }}</p>
              <p>{{ endInfo.coupon.discountType == '0' ? '折扣券' : '满减券' }}</p>
            </div>
          </div>

          <div class="container-imgBox container-item" v-if="endInfo.prize == '6'">
            <van-image
              width="50"
              height="80"
              fit="cover"
              :src="endInfo.prizeImg"
            />
          </div>

          <p class="container-title container-item" v-if="endInfo.prize == '6'">实物：{{ endInfo.name }}</p>
          <p class="container-title container-item" v-else>奖品已放在你的账户中~</p>

          <div class="container-qvkankan container-item">
            <span class="container-qvkankan-text">看广告获得{{ active }}活跃度</span>
            <span>
              <van-button 
                round 
                size="mini" 
                color="linear-gradient(to right, #FF4982, #FF3F3F)"
                @click="qvzhuanqv">
                <div style="margin: 0 3px;">去赚取</div>
              </van-button>
            </span>
          </div>
          
          <div class="container-btn">
            <span
              class="container-btn-item" 
              style="margin-right: 10px;"
              v-if="endInfo.prize == '6'"
              key="prize">
              <van-button 
                round 
                color="linear-gradient(#fffefd, #ffe2b7)"
                @click="shouxia">
                <span style="color: #FF5400;">开心收下</span>
              </van-button>
            </span>
            
            <span
              class="container-btn-item"
              style="margin-right: 10px;"
              v-else
              key="else">
              <van-button 
                round 
                color="linear-gradient(#ffb85f, #ffe9c5)"
                @click="tryAgain">
                <span style="color: #FF5400;">再来一次</span>
              </van-button>
            </span>

            <span class="container-btn-item">
              <van-button 
                round 
                color="linear-gradient(#ffb85f, #ffe9c5)"
                @click="shareFriend">
                <span style="color: #FF5400;">分享好友</span>
              </van-button>
            </span>
          </div>
        </div>

        <div>
          <van-icon name="close" size="30" color="#fff" @click="shouxia" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },

  props: {
    show: {
      type: Boolean,
      required: true
    },
    endInfo: {
      type: Object,
    },
    active: {
      type: Number
    }
  },

  computed: {
    _show: {
      get () {
        return this.show
      },
      set () {
        this.$emit('closeShow')
      }
    }
  },

  methods: {
    hideMask () {
      this._show = false
    },

    tryAgain () {
      this._show = false
      this.$emit('tryAgain')
    },

    shouxia () {
      this._show = false
      if (this.endInfo.prize == '6') {
        this.$emit('closeShow', true)
      }
    },

    qvzhuanqv () {
      this._show = false
      this.$emit('qvzhuanqv')
    },

    shareFriend () {
      this._show = false
      this.$emit('openShareFriend')
    }
  }
}
</script>

<style lang="less" scoped>
.zhezhaoceng {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-title {
    font-size: 24px;
    color: #FFE2B4;
  }
  .container {
    width: 90%;
    margin: 100px auto 50px;
    padding: 10px 20px 25px;
    border: 1px solid #ffee96;
    border-radius: 20px;
    box-sizing: border-box;
    background: linear-gradient(#ff5822 50%, #fdd26b);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &-item {
      margin-bottom: 20px;
    }
    &-img {
      width: 200px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &-jine {
      color: #fff;
    }
    &-ticket {
      position: relative;
      &-img {
        width: 250px;
        height: 100px;
      }
      &-ticketName {
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    &-qvkankan {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      padding: 10px;
      background: #FFE6C1;
      box-sizing: border-box;
      &-text {
        color: #832604;
      }
    }
    &-imgBox {
      width: 120px;
      height: 120px;
      border-radius: 60px;
      background: linear-gradient(#fcb74e, #fa7c3d);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-title {
      color: #fff;
      font-size: 20px;
    }
    &-btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &-item {
        flex: 1;
      }
      .van-button--round {
        width: 100%;
      }
    }
  }
}
.ani-enter {
  opacity: 0;
}
.ani-leave {
  opacity: 1;
}
.ani-leave-to {
  opacity: 0;
}
.ani-enter-active,
.ani-leave-active {
  transition: all .5s;
}
</style>