<template>
  <div class="container">
    <van-nav-bar 
      title="" 
      fixed 
      @click-left="onClickLeft" 
      left-arrow />

    <div class="lunbo">
      <div class="jiangRecordsList">
        <div class="jiangRecordsList-item" v-for="(x, i) in list" :key="i">{{ x }}</div>
      </div>
    </div>

    <div class="jiangRecords" @click="enterRecords">
      <div class="jiangRecords-item">
        <span>中奖</span>
        <span>记录</span>
      </div>
    </div>

    <div class="container-dazhuanpan">
      <div class="container-dazhuanpan-noactive" v-if="noneActive">
        <div class="container-dazhuanpan-noactive-box">
          <img class="container-dazhuanpan-noactive-box-img" src="./img/noActive.png" alt="">
        </div>
      </div>
      <div style="margin-top: 24px;">
        <LuckyWheel
          ref="myLucky"
          width="255px"
          height="255px"
          :prizes="prizes"
          :blocks="blocks"
          :buttons="buttons"
          @start="startCallback"
          @end="endCallback"
          class="dazhuanpan" />
      </div>

      <p class="container-dazhuanpan-huoyuedu" v-if="noneActive">暂无活动</p>
      <p class="container-dazhuanpan-huoyuedu" v-else>今日活跃度 {{ userActive }}</p>
    </div>

    <div class="activeRule">
      <p class="activeRule-text">
        <span class="activeRule-text-zi">活动规则</span>
      </p>
      <ul class="activeRule-ul" v-if="activityRules">
        <li class="activeRule-ul-li" v-for="(item,index) in activityRules" :key="index">{{ item }}</li>
      </ul>
      <ul v-else class="activeRule-ul">
        <li class="activeRule-ul-li">暂无数据</li>
      </ul>
    </div>

    <div class="userListBox">
      <div class="userList" v-for="x in tiskList" :key="x.id">
        <div class="userList-btn">
          <span class="userList-btn-name">{{ x.name }}</span>
          <van-button 
            round 
            size="mini" 
            color="linear-gradient(to right, #FF4982, #FF3F3F)" 
            @click="goGuang(x.activeType)">
            <div style="transform: scale(.9); margin: 0 3px;">去赚取</div>
          </van-button>
        </div>

        <div class="userList-item" v-for="(y, i) in x.list" :key="i">
          <div class="userList-item-yuan"></div>
          <div class="userList-item-content">
            <span>{{ y.activeTypeName }}</span>
            <span style="color: #E54320;">+{{ y.active }}</span>
            <span>{{ y.activeTyDanwei }}</span>
          </div>
        </div>
      </div>

      <p v-if="Apple" style="font-size: 14px; color: #E54320; text-align: center;">该活动及奖励与Apple Inc.无关</p>
    </div>

    <vant-dialong 
      :show="show" 
      :endInfo="endInfo"
      :active="active"
      @closeShow="closeShow" 
      @tryAgain="tryAgain"
      @qvzhuanqv="qvzhuanqv"
      @openShareFriend="openShareFriend" />

    <none-jiang 
      :NoneJiang="NoneJiang"
      :active="active"
      @closeNoneJiang="closeNoneJiang" 
      @tryAgain="tryAgain"
      @qvzhuanqv="qvzhuanqv" />

    <coin-chance 
      :CoinChance="CoinChance" 
      :zhifuType="zhifuType" 
      :active="active"
      @closeCoinChance="closeCoinChance"
      @duihuanChance="duihuanChance"
      @qvzhuanqv="qvzhuanqv" />

    <select-address
      ref="SelectAddress"
      :SelectAddress="SelectAddress" 
      @closeSelectAddress="closeSelectAddress" />

    <share-friend
      ref="ShareFriend"
      :ShareFriend="ShareFriend"
      :endInfo="endInfo"
      :src="src"
      @closeShareFriend="closeShareFriend" />

    <loading :loading="loading" @closeLoading="closeLoading" />
  </div>
</template>

<script>

import VantDialong from './components/VantDialong'
import NoneJiang from './components/NoneJiang'
import CoinChance from './components/CoinChance'
import ShareFriend from './components/ShareFriend'
import SelectAddress from './components/SelectAddress'
import Loading from './components/Loading'
import { getActivePage, choujiang, getTiskList, getErCode } from '@/api/Ge/port'
import { Toast } from 'vant'
import { mapMutations, mapState } from 'vuex'

export default {
  components: { VantDialong, NoneJiang, CoinChance, SelectAddress, Loading, ShareFriend },

  data () {
    return {
      show: false,
      NoneJiang: false,
      CoinChance: false,
      SelectAddress: false,
      ShareFriend: false,
      userActive: 0,
      formInline: {
        id: '',
        payType: '1'
      },
      zhifuType: [],
      info: {},
      endInfo: {},
      src: '',
      loading: false,
      tiskList: [],
      activeAmount: 0,
      activityRules: [],
      discharged: '',
      noneActive: true,
      list: [],
      active: 0,

      blocks: [],
      prizes: [],
      buttons: [
        {
          radius: '30%',
          imgs: [{
            src: 'https://ydcp.oss-cn-beijing.aliyuncs.com/1/material/6e90a615-86a7-48aa-9261-bbb40c705fe2.png',
            width: '80',
            height: '90',
            top: '-50'
          }]
        }
      ],
      Apple: true
    }
  },

  watch: {
    SelectAddress (Boolean) {
      if (!Boolean) {
        this['zhuanpan/set_list_pan'](false)
        this.$refs.SelectAddress.radio = ''
      }
    }
  },

  computed: {
    ...mapState({
      list_pan: state => state.zhuanpan.list_pan
    })
  },

  created () {
    if (this.$route.query.session) {
      this.$store.commit('setSession', this.$route.query.session)
    }
  },

  mounted () {
    this.isApple()
    this.getPage()
    this.getTiskList()
    this.getJiangliList()
    this.getErCode()
    window.rewardVideoSuccess  = this.rewardVideoSuccess
    setTimeout(() => {
      if (this.list_pan) {
        this.SelectAddress = true
      }
    }, 500)
    let screenWidth = document.documentElement.getBoundingClientRect().width
    document.documentElement.style.fontSize = (screenWidth / 10) + 'px'
  },

  methods: {
  ...mapMutations(['zhuanpan/set_rewardId', 'zhuanpan/set_list_pan']),

    startCallback () {
      if (this.noneActive) {
        Toast('暂无活动')
      } else {
        if (this.discharged == '1') {
          if (this.userActive < this.activeAmount) {
            if (this.zhifuType.length) {
              this.CoinChance = true
            } else {
              Toast('活跃度不足')
            }
          } else {
            this.choujiang()
          }
        } else {
          Toast('活跃度不足')
        }
      }
    },

    // 抽奖结束会触发end回调
    endCallback (res) {
      if (JSON.stringify(res) != '{}') {
        this.choujiangEnd()
        this.loading = true
        getActivePage().then(res => {
          if (res.data.code === 0) {
            this.loading = false
            this.userActive = res.data.data.userActive
          } else {
            this.loading = false
          }
        }).catch(() => {
          this.loading = false
        })
      }
    },

    onClickLeft () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up")
      }
      if (isAndroid) {
        window.android.close()
      }
    },

    isApple () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        this.Apple = true
      }
      if (isAndroid) {
        this.Apple = false
      }
    },

    closeShow (Boolean) {
      this.show = false
      if (Boolean) {
        this.SelectAddress = true
      }
    },

    tryAgain () {
      setTimeout(() => {
        this.startCallback()
      }, 100)
    },

    closeLoading () {
      this.closeLoading = false
    },

    enterRecords () {
      this.$router.push({
        path: '/slyderAdventuresRecords'
      })
    },

    closeNoneJiang () {
      this.NoneJiang = false
    },

    closeCoinChance () {
      this.CoinChance = false
    },

    duihuanChance (type) {
      this.formInline.payType = type
      this.choujiang()
    },

    closeSelectAddress () {
      this.SelectAddress = false
    },

    closeShareFriend () {
      this.ShareFriend = false
    },

    openShareFriend () {
      this.loading = true
      setTimeout(() =>{
        this.loading = false
        this.ShareFriend = true
        this.$nextTick(() => {
          this.$refs.ShareFriend.toImage()
        })
      }, 1000)
    },

    qvzhuanqv () {
      this.showRewardVideo()
    },

    rewardVideoSuccess () {
      getActivePage().then(res => {
        if (res.data.code === 0) {
          this.loading = false
          this.userActive = res.data.data.userActive
        } else {
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    },

    showRewardVideo () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.showRewardVideo.postMessage("1")
      }
      if (isAndroid) {
        window.android.showRewardVideo('1')
      }
    },

    activityTaskVideo () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.activityTaskVideo.postMessage("da")
      }
      if (isAndroid) {
        window.android.activityTaskVideo()
      }
    },

    activityTaskMall () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.activityTaskMall.postMessage("da")
      }
      if (isAndroid) {
        window.android.activityTaskMall()
      }
    },

    setSlydering () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.setSlydering.postMessage("da")
      }
      if (isAndroid) {
        window.android.setSlydering()
      }
    },

    getSound () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.getSound.postMessage("da")
      }
      if (isAndroid) {
        window.android.getSound()
      }
    },

    choujiang () {
      this.$refs.myLucky.play()
      this.setSlydering()
      choujiang(this.formInline).then(res => {
        if (res.data.code === 0) {
          const prizeInfo = res.data.data.prizeInfo
          const i = this.prizes.findIndex(x => x.prizeInfo == prizeInfo)
          this.$refs.myLucky.stop(i)

          let endInfo = res.data.data
          let prizeName = ''
          let typeName = ''
          switch (res.data.data.prize) {
            case '1': prizeName = '优惠券', typeName = '优惠券'; break
            case '2': prizeName = '元', typeName = '现金'; break
            case '3': prizeName = '驿马', typeName = '驿马'; break
            case '4': prizeName = '金叶子', typeName = '金叶子'; break
            case '5': prizeName = '官凭', typeName = '官凭'; break
            case '6': prizeName = '商品', typeName = '商品盲盒'; break
          }
          endInfo.prizeName = prizeName
          endInfo.typeName = typeName
          this.endInfo = endInfo
          this['zhuanpan/set_rewardId'](res.data.data.rewardId)
        } else {
          Toast(res.data.message)
          this.$refs.myLucky.stop()
        }
      }).catch(() => {
        Toast('服务繁忙')
        this.$refs.myLucky.stop()
      })
    },

    choujiangEnd () {
      this.loading = true
      let endInfo = JSON.parse(JSON.stringify(this.endInfo))
      if (endInfo.prize == '7') {
        setTimeout(() => {
          this.loading = false
          this.NoneJiang = true
        }, 500)
      } else {
        setTimeout(() => {
          this.loading = false
          this.getSound()
          setTimeout(() => {
            this.show = true
          }, 1000)
        }, 500)
      }
    },

    getPage () {
      getActivePage().then(res => {
        if (res.data.code === 0) {
          if (res.data.data) {
            this.noneActive = false
            const info = res.data.data
            this.discharged = res.data.data.discharged
            this.userActive = res.data.data.userActive
            this.activeAmount = Number(res.data.data.activeAmount)
            this.activityRules = res.data.data.activityRules ? res.data.data.activityRules.split('\n'):[]
            this.formInline.id = res.data.data.id
            let zhifuType = []
            res.data.data.ricePay == '1' ? zhifuType.push({ type: '2', name: '驿马', jine: Number(info.riceAmount) }) : ''
            res.data.data.goodPay == '1' ? zhifuType.push({ type: '3', name: '金叶子', jine: Number(info.goodAmount) }) : ''
            res.data.data.officialPay == '1' ? zhifuType.push({ type: '4', name: '官凭', jine: Number(info.officialAmount) }) : ''
            this.zhifuType = zhifuType

            const prizes = []
            res.data.data.prizeInfoList.forEach((x, i) => {
              prizes.push({
                prizeInfo: x.prizeInfo,
                background: i % 2 == 0 ? '#fee1aa' : '#fef6d0',
                fonts: [{ text: x.name, fontColor: '#FC6742', fontSize: '12px', top: '5px' }],
                imgs: [
                  {
                    src: x.prizeImg,
                    width: '38px',
                    height: '38px',
                    top: '30px'
                  }
                ]
              })
            })
            this.prizes = prizes
          } else {
            this.noneActive = true
          }
        }
      })
    },

    getTiskList() {
      getTiskList().then(res => {
        if (res.data.code === 0) {
          let tiskList = [
            { name: '邀请好友助力', activeType: '0', list: [] },
            { name: '看广告', activeType: '1', list: [] },
            { name: '逛逛武林', activeType: '2', list: [] },
            { name: '浏览商城', activeType: '3', list: [] }
          ]
          res.data.data.forEach(x => {
            if (x.activeType == 3) {
              this.active = x.active
            }
            let activeTypeName = ''
            let activeTyDanwei = ''
            switch (x.activeType) {
              case 1: activeTypeName = '邀请1个新用户助力可', activeTyDanwei = '活跃度/位', tiskList[0].list.push(x); break
              case 2: activeTypeName = '邀请1个老用户助力可', activeTyDanwei = '活跃度/位', tiskList[0].list.push(x); break
              case 3: activeTypeName = '看广告可获得', activeTyDanwei = '活跃度/个', tiskList[1].list.push(x); break
              case 4: activeTypeName = '观看1个短视频', activeTyDanwei = '活跃度/个', tiskList[2].list.push(x); break
              case 5: activeTypeName = '给短视频点赞', activeTyDanwei = '活跃度/个', tiskList[2].list.push(x); break
              case 6: activeTypeName = '举报视频平台审核通过', activeTyDanwei = '活跃度/个', tiskList[2].list.push(x); break
              case 8: activeTypeName = '举报评论平台审核通过', activeTyDanwei = '活跃度/个', tiskList[2].list.push(x); break
              case 9: activeTypeName = '给短视频评论', activeTyDanwei = '活跃度/个', tiskList[2].list.push(x); break
              case 7: activeTypeName = '每浏览商城', activeTyDanwei = '活跃度/分钟', tiskList[3].list.push(x); break
            }
            x.activeTypeName = activeTypeName
            x.activeTyDanwei = activeTyDanwei
          })
          this.tiskList = tiskList
        }
      })
    },

    goGuang (activeType) {
      if (activeType == '0') {
        this.$router.push({
          path: '/invitationRegister',
          query: {
            session: this.$route.query.session
          }
        })
      } else if (activeType == '1') {
        this.showRewardVideo()
      } else if (activeType == '2') {
        this.activityTaskVideo()
      } else if (activeType == '3') {
        this.activityTaskMall()
      }
    },

    getErCode () {
      let params = {
        content: "http://mall.hfzlwl.com/app/#/loginShare",
        // content: "http://192.168.0.184:8081/#/loginShare",
        width: 200,
        height: 200,
        logoUrl: '',
        logoWidth: 50,
        logoHeight: 50,
        types: '7'
      }
      getErCode(params).then(res => {
        if (res.data.code === 0) {
          this.src = res.data.data.downloadUrl
        }
      }).catch(() =>{
      })
    },

    getJiangliList () {
      this.list = [
        '186****8432抽中了5元满减劵',
        '138****2461抽中了10元满减劵',
        '186****6581抽中了1元代金劵',
        '190****8654抽中了1元代金劵',
        '134****2461抽中了现金包',
        '137****8956抽中了2元代金劵',
        '186****7415抽中了5元满减劵',
        '135****8562抽中了20元满减劵',
        '133****4541抽中了10元满减劵',
        '181****6359抽中了5元代金劵',
        '171****3725抽中了现金红包',
        '134****8164抽中了3元代金劵',
        '183****1351抽中了2元代金劵',
        '136****2658抽中了5元满减劵',
        '131****8941抽中了10元满减劵',
        '186****5621抽中了1元代金劵',
        '131****8946抽中了1元代金劵',
        '451****8462抽中了5元代金劵',
        '156****8956抽中了1元代金劵',
        '191****8956抽中了1元代金劵',
        '154****3212抽中了现金包',
        '183****1212抽中了2元代金劵',
        '136****6133抽中了5元满减劵',
        '131****2311抽中了20元满减劵',
        '153****7895抽中了10元满减劵',
        '191****8956抽中了现金红包',
        '153****1212抽中了5元代金劵',
        '154****3212抽中了3元代金劵',
        '183****1212抽中了2元代金劵',
        '136****6133抽中了5元满减劵',
        '131****2311抽中了10元满减劵',
        '186****8545抽中了1元代金劵',
        '131****2526抽中了1元代金劵',
        '134****8892抽中了现金包',
        '133****2451抽中了2元代金劵',
        '183****8951抽中了5元代金劵',
        '156****1363抽中了5元满减劵',
        '137****7651抽中了20元满减劵',
        '183****3268抽中了10元满减劵',
        '191****8513抽中了现金红包',
        '134****3892抽中了3元代金劵',
        '183****1212抽中了2元代金劵',
        '191****6542抽中了5元代金劵',
        '134****8892抽中了现金包',
        '133****1471抽中了2元代金劵',
        '156****9512抽中了5元满减劵',
        '177****8741抽中了20元满减劵',
        '153****3556抽中了10元满减券',
        '131****8413抽中了现金红包',
        '151****5689抽中了3元代金劵',
        '153****8142抽中了2元代金劵',
        '136****8951抽中了5元满减劵',
        '138****7412抽中了10元满减劵',
        '136****7894抽中了5元代金劵',
        '189****5946抽中了1元代金劵',
        '156****5654抽中了1元代金劵',
        '134****7864抽中了现金包',
        '138****1271抽中了2元代金劵',
        '151****1020抽中了5元满减劵',
        '191****0281抽中了20元满减劵',
        '183****7105抽中了10元满减劵',
        '131****0894抽中了现金红包',
        '173****1102抽中了5元代金劵',
        '183****1810抽中了3元代金劵',
        '191****8741抽中了2元代金劵',
        '156****8955抽中了5元满减劵',
        '131****1208抽中了10元满减劵',
        '191****0151抽中了1元代金劵',
        '156****8121抽中了1元代金劵',
        '137****8121抽中了现金包',
        '133****8121抽中了2元代金劵',
        '183****8711抽中了5元代金劵',
        '136****0351抽中了5元满减劵',
        '187****7621抽中了20元满减劵',
        '183****3845抽中了10元满减劵',
        '191****8057抽中了现金红包',
        '134****8914抽中了3元代金劵',
        '183****1212抽中了2元代金劵',
        '191****6542抽中了5元代金劵',
        '136****1540抽中了5元满减劵',
        '188****7106抽中了10元满减劵',
        '186****6011抽中了1元代金劵',
        '190****8104抽中了1元代金劵',
        '134****4611抽中了现金包',
        '137****8956抽中了2元代金劵',
        '186****7415抽中了5元满减劵'
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.van-hairline--bottom::after {
  border-bottom-width: 0;
}
/deep/ .van-nav-bar {
  background: transparent;
  .van-nav-bar__left,
  .van-nav-bar__right {
    padding: 0 15px;
  }
  .van-nav-bar__title,
  .van-icon-arrow-left {
    color: #000;
  }
}
@keyframes scroll {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-200%);
  }
}
.lunbo {
  width: 100%;
  position: absolute;
  right: 0;
  top: 100px;
  overflow: hidden;
}
.jiangRecordsList {
  width: 100%;
  display: flex;
  font-size: 12px;
  color: #E54320;
  display: flex;
  align-items: center;
  animation: scroll 20s linear infinite;
  &-item {
    background: rgba(254, 234, 218, .5);
    margin-right: 15px;
    flex-shrink: 0;
    border: 1px solid #fff;
    border-radius: 50px;
    padding: 2px 10px;
  }
}
.jiangRecords {
  display: flex;
  position: absolute;
  right: 0;
  top: 145px;
  &-item {
    padding: 5px;
    color: #fff;
    font-size: 12px;
    border: 1px solid #fff;
    border-right: 1px solid transparent;
    background: rgba(0, 0, 0, .4);
    border-radius: 10px 0 0 10px;
    display: flex;
    flex-direction: column;
  }
}
.container {
  min-height: 100%;
  background: url(./img/beijing.png) no-repeat;
  background-size: 100% auto;
  padding-top: 150px;
  box-sizing: border-box;
  position: relative;
  &-dazhuanpan {
    width: 300px;
    height: 366px;
    margin: auto;
    background: url(./img/beijing.gif) no-repeat;
    background-size: 100% auto;
    display: flex;
    justify-content: center;
    position: relative;
    &-huoyuedu {
      color: #C61001;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
    }
    &-noactive {
      width: 100%;
      aspect-ratio: 1/1;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
      &-box {
        width: 255px;
        height: 255px;
        border-radius: 50%;
        background: rgba(255, 248, 225, .5);
        display: flex;
        justify-content: center;
        align-items: center;
        &-img {
          widows: 100%;
          aspect-ratio: 1.38/1;
        }
      }
    }
  }
}
.activeRule {
  width: 95%;
  background: linear-gradient(#FFE5D1, #fff 50px);
  border-radius: 15px;
  margin: 15px auto;
  padding: 0 15px 15px;
  box-sizing: border-box;
  &-text {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    &-zi {
      color: #FF5400;
      background: #FFD5A5;
      padding: 10px 40px;
      border-radius: 0 0 15px 15px;
    }
  }
  &-ul {
    font-size: 14px;
    &-li {
      margin-bottom: 5px;
    }
  }
}
.userListBox {
  background: #fdd4a0;
  padding-bottom: 10px;
}
.userList {
  width: 95%;
  margin: auto;
  background: #fff;
  border-radius: 15px;
  padding: 13px;
  box-sizing: border-box;
  margin-bottom: 10px;

  &-btn {
    display: flex;
    justify-content: space-between;
    &-name {
      color: #671A0E;
      font-size: 16px;
      font-weight: bold;
    }
  }
  &-item {
    color: #333;
    font-size: 14px;
    display: flex;
    align-items: center;
    &-yuan {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background: #FFB68C;
      display: flex;
      align-items: center;
      margin-right: 5px;
    }
  }
}
</style>